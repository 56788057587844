import React from 'react'
import { Link } from 'gatsby'
import { useStyletron } from 'baseui'
import { Table } from 'baseui/table'
import { H2, Paragraph3, Paragraph2 } from 'baseui/typography'
import { FcCheckmark } from 'react-icons/fc'
import { BsDash } from 'react-icons/bs'
import Emoji from './Emoji'
import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from 'baseui/popover'
import { isMobile } from 'react-device-detect'

const Pricing = () => {
  const [css] = useStyletron()

  const pricingContainer = css({
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    marginTop: '2rem',
    marginBottom: '1rem',
  })

  const pricingTitle = css({
    marginBottom: '2rem',
    textAlign: 'center',
  })

  const tableHeader = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
  })

  return (
    <div className={pricingContainer}>
      <div className={pricingTitle} id="pricing">
        <H2>Compare our services:</H2>
        {/* <Paragraph3>
          We want your business to grow with us. Start for free, then be a Pro
        </Paragraph3> */}
      </div>
      <div className="pricingTable">
        <Table
          columns={[
            <div className={tableHeader}>
              Features included
              <small>
                {isMobile
                  ? '(tap for more info)'
                  : '(hover over for more info)'}
              </small>
            </div>,
            <PlanHeader
              title="Walink Free"
              description="USD $0 Forever"
              to="/#create-link"
              // emoji="😁"
            />,
            <PlanHeader
              title="Walink Premium"
              description="USD $6/year per link"
              emoji="👑"
              to="/premium"
            />,
          ]}
          data={[
            [
              <PlanItem content="Increase chat conversion of your clicks with the wa.link domain. Users know that it will open a WhatsApp chat, making it more effective than other alternatives.">
                Click to chat with wa.link domain
              </PlanItem>,
              <Check />,
              <Check />,
            ],
            [
              <PlanItem content="Add a custom message that users may send to you to start the conversation, without complex encoding or affecting link's length.">
                Custom message
              </PlanItem>,
              <Check />,
              <Check />,
            ],
            [
              <PlanItem content="Easy to scan QR Code that will open a WhatsApp chat, just like the link.">
                QR Code
              </PlanItem>,
              <Check />,
              <Check />,
            ],
            [
              <PlanItem content="Branded links are a professional way to share your WhatsApp in any digital and traditional channel, like TV, radio, images or videos. Users can reach your chat just by typing your branded link in any web browser. No need for remember phone numbers.">
                Branded link (wa.link/YourBrand)
              </PlanItem>,
              <Not />,
              <Check />,
            ],
            [
              <PlanItem content="Change your link's info at anytime. Update custom message and phone number without worrying about loosing customers, or modify the URL if you need to.">
                Update link info (phone, url and custom message)
              </PlanItem>,
              <Not />,
              <Check />,
            ],
            [
              <PlanItem content="Track the amount of clicks on your links by day, hour and month. Also, get the source, Operating System and country of your clients.">
                Clicks analytics
              </PlanItem>,
              <Not />,
              <Check />,
            ],
            [
              <PlanItem content="Make your WhatsApp easier to find by listing your business in our search engine (open.wa.link/search), used by thousands of people every day to open WhatsApp chats without saving contacts.">
                Appear in search results
              </PlanItem>,
              <Not />,
              <Check />,
            ],
            [
              <PlanItem content="Create links for your WhatsApp Business catalog with an easy to remember URL. Users can click the link and your products will show up instantly for purchase.">
                WhatsApp catalog links
              </PlanItem>,
              <Not />,
              <Check />,
            ],
            [
              <PlanItem content="More than one WhatsApp line in your business? Gather all your links inside a Multilink page to provide options for your customers reach you in a single point of contact: your Multilink page!">
                Multilink page*
              </PlanItem>,
              <Not />,
              <Check />,
            ],
            [
              <PlanItem content="Our support team will be ready to help and solve your inquiries via email">
                Email support
              </PlanItem>,
              <Not />,
              <Check />,
            ],
            [
              <PlanItem content="Increase the amount of chats you can handle by automatically distributing incoming chats across multiple WhatsApp phone numbers, so you can have multiple agents behind a single wa.link!">
                Multiagent (multiple WhatsApp lines, one single wa.link)
              </PlanItem>,
              <Not />,
              <div style={{ textAlign: 'center', width: '100%' }}>
                <small>USD $5/agent/month</small>
                <div>
                  <small>(14 days free trial)</small>
                </div>
              </div>,
            ],
          ]}
        />
      </div>
      <div style={{ paddingLeft: '15px' }}>
        <Paragraph3>
          <small>
            * Multilink page is available for customers with 2 or more links in
            their Premium Plan
          </small>
        </Paragraph3>
      </div>
    </div>
  )
}

function Check() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <FcCheckmark size="18px" />
    </div>
  )
}

function Not() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <BsDash size="18px" style={{ color: 'darkgrey' }} />
    </div>
  )
}

function PlanHeader({ title, description, emoji, to }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        textAlign: 'center',
      }}
    >
      <div>
        <strong>
          {emoji && <Emoji symbol={emoji} />} <Link to={to}>{title}</Link>
        </strong>
      </div>
      <div>
        <small>{description}</small>
      </div>
    </div>
  )
}

function PlanItem({ children, content }) {
  return (
    <StatefulPopover
      showArrow
      content={
        <>
          <Paragraph2 padding="scale300">
            <strong>{children}:</strong>
            <br />
            {content}
          </Paragraph2>
        </>
      }
      accessibilityType={'tooltip'}
      triggerType={TRIGGER_TYPE.hover}
      placement={PLACEMENT.left}
      overrides={{
        Body: {
          style: {
            width: '280px',
            marginRight: '-200px',
            position: 'absolute',
            textAlign: 'center',
            borderTopLeftRadius: '15px',
            borderBottomLeftRadius: '15px',
            borderTopRightRadius: '15px',
            borderBottomRightRadius: '15px',
          },
        },
        Inner: {
          style: {
            borderTopLeftRadius: '15px',
            borderBottomLeftRadius: '15px',
            borderTopRightRadius: '15px',
            borderBottomRightRadius: '15px',
          },
        },
      }}
    >
      {children}
    </StatefulPopover>
  )
}

export default Pricing
